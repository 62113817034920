import React from 'react';
import cmnStyles from 'styles/pages.module.scss';
import styles from './Article.module.scss';

type ArticleProps = {
  classOverride?: string;
}

const AboutArticle: React.FC<ArticleProps> = ({
  children, classOverride = '',
}) => (
  <p
    className={`${cmnStyles.article} ${styles.mainText} ${classOverride}`}
  >
    {children}
  </p>
);

export default AboutArticle;
