import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useStaticQuery, graphql } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import { FormattedMessage } from 'react-intl';

import cmnStyles from 'styles/pages.module.scss';
import AboutArticleTitle from '../ArticleTitle';
import AboutArticle from '../Article';
import animations from '../Animations.module.scss';
import AboutSocial from './Social';

const AboutIntro: React.FC = () => {
  const {
    imageIntro,
  } = useStaticQuery(
    graphql`
        query {
            imageIntro: file(relativePath: { eq: "about/intro.png" }) {
                childImageSharp {
                    fluid(maxWidth: 500) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64,
                    }
                }
            }
        }
    `,
  );
  return (
    <Container>
      <Row>
        <Col
          xs={{ span: 12, order: 2 }}
          sm={{ span: 12, order: 2 }}
          md={{ span: 12, order: 2 }}
          lg={{ span: 5, order: 1 }}
          xl={{ span: 5, order: 1 }}
          className={cmnStyles.alignSectionVerticallyCenter}
        >
          <AboutArticleTitle>
            <FormattedMessage id="components.about.title" />
          </AboutArticleTitle>
          <AboutArticle>
            <FormattedMessage id="components.about.intro" />
          </AboutArticle>
          <AboutSocial />
        </Col>
        <Col
          xs={{ span: 12, order: 1 }}
          sm={{ span: 12, order: 1 }}
          md={{ span: 12, order: 1 }}
          lg={{ span: 6, order: 2 }}
          xl={{ span: 6, order: 2 }}
        >
          <div className={animations.introImage}>
            <GatsbyImage fluid={imageIntro.childImageSharp.fluid} />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default AboutIntro;
