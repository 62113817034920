import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { getLocale, Locales } from 'helpers/utils/locale';
import { stringContainsWords } from 'helpers/utils/url';

import { isMobileOS } from 'helpers/utils/mobile';
import ActionLinkButton from 'components/UI/Buttons/ActionLinkButton';
import Links from 'helpers/constants/links';

import './BannerTransformButton.scss';
import styles from './BannerTransform.module.scss';

const BannerTransform: React.FC = () => {
  const [isMobile, setMobile] = useState(false);
  const demoUrl = Links.BANNERS.DEMO;
  const intl = useIntl();
  const [isCurrentPageHome, setIsCurrentPageHome] = useState(false);

  useEffect(() => {
    setIsCurrentPageHome(stringContainsWords(window.location.href, 'home', 'accueil'));
    setMobile(isMobileOS());
  }, []);

  const requestDemoTitle = intl.formatMessage({ id: 'components.banners.bannerTransform.buttonRequestDemo' });

  const calendlyEN = 'https://calendly.com/onoff-business/phone-meeting?primary_color=37eea3';
  const calendlyFR = 'https://calendly.com/onoff-business/presentation-onoff-business?primary_color=37eea3';
  const calendlyLink = getLocale(intl.locale) === Locales.En ? calendlyEN : calendlyFR;

  const linkDestop = (
    <div
      className={styles.buttons}
       // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: `
          <a
          href="#"
          class="actionButton"
          onclick="Calendly.initPopupWidget({url: '${calendlyLink}'});return false;">
          ${requestDemoTitle}
          </a>
        `,
      }}
    />
  );

  const linkMobile = (
    <ActionLinkButton
      url={demoUrl}
    >
      <FormattedMessage id="components.banners.bannerTransform.buttonRequestDemo" />
    </ActionLinkButton>
  );

  const linkView = !isMobile ? linkDestop : linkMobile;

  return (
    <>
      <Container className={isCurrentPageHome ? styles.mainOnHome : styles.main}>
        <Row>
          <Col className={styles.mainText}>
            <FormattedMessage id="components.banners.bannerTransform.main" />
          </Col>
        </Row>
        <Row className={styles.buttons}>
          <Col className="d-flex justify-content-center">
            {linkView}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default BannerTransform;
